import * as React from "react";
import Navbar from "./layout";

const Layout2 = (props) => {
  return (
    <div className="flex flex-col mx-auto">
      <Navbar></Navbar>
      <div className="container mx-auto">{props.children}</div>
    </div>
  );
};

export default Layout2;
