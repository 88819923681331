import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";
import Layout2 from "../components/layout2";
import * as marked from 'marked';

const SitePosts = ({ data }) => {
  
  
  useEffect(()=>{
    const contentfulBlog = data.contentfulBlog;
    document.getElementById("blogbody").innerHTML = marked.parse(contentfulBlog.postBody.internal.content)
  },[data])
  
  const contentfulBlog = data.contentfulBlog;

  return (
    <Layout2>
      <title>{contentfulBlog.title}</title>
      <div className="ourpost">
        <h1>{contentfulBlog.title}</h1>
        <p id="blogbody"></p>
        <Link to="/blog">View more posts</Link>
        <hr></hr>
        <Link to="/">Back to Home</Link>
      </div>
    </Layout2>
  );
};

export const query = graphql`
  query ($slug: String) {
    contentfulBlog(slug: { eq: $slug }) {
      title
      slug
      postBody {
        id
        internal {
          content
        }
      }
    }
  }
`;

export default SitePosts;
